import './Application.css';
import Drawer from './Drawer';
import Header from './Header';

export default function Application({ application }) {

    return (
        <div className='application'>
            <Header 
                name={application.name}
                status={application.status}
                environment={application.environment}
            />
            <Drawer 
                modules={application.modules}
                tags={application.tags}
            />
        </div>
    );
}