import './App.css';
import Dashboard from './components/dashboard/Dashboard';
import Header from './components/header/Header';

function App() {

  return (
    <div>
      <Header />
      <Dashboard />
    </div>
  );
}

export default App;
