// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application {
    flex-basis: 18%;
    flex-grow: 0;
    flex-shrink: 1;
    margin-bottom: 1.5em;
    margin-right: 2%;
    background-color: white;
    font-size: 0.9em;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1620px) {
    .application {
      flex-basis: 23%;
    }
    .application:nth-child(5n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 1290px) {
    .application {
      flex-basis: 31.33333%;
    }
    .application:nth-child(4n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 1000px) {
    .application {
      flex-basis: 48%;
    }
    .application:nth-child(3n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 675px) {
    .application {
      flex-basis: 98%;
    }
    .application:nth-child(2n) {
      margin-right: 2%;
    }
}
  
@media screen and (max-width: 675px) {
    .application {
      margin-bottom: 1em;
    }
}
  `, "",{"version":3,"sources":["webpack://./src/components/application/Application.css"],"names":[],"mappings":"AAAA;IAEI,eAAe;IAGf,YAAY;IAEZ,cAAc;IACd,oBAAoB;IACpB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAEhB,0CAA0C;AAC9C;;AAEA;IACI;MAEM,eAAe;IACrB;IACA;MACE,gBAAgB;IAClB;AACJ;;AAEA;IACI;MAEM,qBAAqB;IAC3B;IACA;MACE,gBAAgB;IAClB;AACJ;;AAEA;IACI;MAEM,eAAe;IACrB;IACA;MACE,gBAAgB;IAClB;AACJ;;AAEA;IACI;MAEM,eAAe;IACrB;IACA;MACE,gBAAgB;IAClB;AACJ;;AAEA;IACI;MACE,kBAAkB;IACpB;AACJ","sourcesContent":[".application {\n    -ms-flex-preferred-size: 18%;\n    flex-basis: 18%;\n    -webkit-box-flex: 0;\n    -ms-flex-positive: 0;\n    flex-grow: 0;\n    -ms-flex-negative: 1;\n    flex-shrink: 1;\n    margin-bottom: 1.5em;\n    margin-right: 2%;\n    background-color: white;\n    font-size: 0.9em;\n    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);\n    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);\n}\n\n@media screen and (max-width: 1620px) {\n    .application {\n      -ms-flex-preferred-size: 23%;\n          flex-basis: 23%;\n    }\n    .application:nth-child(5n) {\n      margin-right: 2%;\n    }\n}\n  \n@media screen and (max-width: 1290px) {\n    .application {\n      -ms-flex-preferred-size: 31.33333%;\n          flex-basis: 31.33333%;\n    }\n    .application:nth-child(4n) {\n      margin-right: 2%;\n    }\n}\n  \n@media screen and (max-width: 1000px) {\n    .application {\n      -ms-flex-preferred-size: 48%;\n          flex-basis: 48%;\n    }\n    .application:nth-child(3n) {\n      margin-right: 2%;\n    }\n}\n  \n@media screen and (max-width: 675px) {\n    .application {\n      -ms-flex-preferred-size: 98%;\n          flex-basis: 98%;\n    }\n    .application:nth-child(2n) {\n      margin-right: 2%;\n    }\n}\n  \n@media screen and (max-width: 675px) {\n    .application {\n      margin-bottom: 1em;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
