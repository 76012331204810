import './AppGrid.css'
import { useState, useEffect } from 'react';
import * as APIUtils from '../../api/APIUtils';
import Application from '../application/Application';

export default function AppGrid() {
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = () => {
        fetchEnvironments()
            .then(environments => environments.map(environment => getApplicationsByEnvironment(environment)));
    }

    const getApplicationsByEnvironment = environment => {
        fetchApplicationNames(environment)
            .then(applicationNames => {
                applicationNames.map(applicationName => {
                    fetchApplication(environment, applicationName)
                        .then(application => {
                            application.environment = environment;
                            setApplications(applications => [...applications, application])
                        })
                });
            });
    }

    const fetchEnvironments = async () => {
        return await new Promise(resolve => {
            const path = '/api/environments';
            APIUtils.get(path).then(({status, json}) => {
                switch(status) {
                    case 200:
                        resolve(json);
                        return;
                    case 400:
                    case 500:
                    default:
                        resolve([]);
                }
            });
        });
    }

    const fetchApplicationNames = async environment => {
        return await new Promise(resolve => {
            const path = '/api/' + environment + '/applications';
            APIUtils.get(path).then(({status, json}) => {
                switch(status) {
                    case 200:
                        resolve(json);
                        return;
                    case 400:
                    case 500:
                    default:
                        resolve([]);
                }
            });
        });
    }

    const fetchApplication = async (environment, applicationName) => {
        return await new Promise(resolve => {
            const path = '/api/' + environment + '/application/' + applicationName;
            APIUtils.get(path).then(({status, json}) => {
                switch(status) {
                    case 200:
                        resolve(json);
                        return;
                    case 400:
                    case 500:
                    default:
                        resolve({});
                }
            });
        });
    }

    const sortApplications = (applications) => {
        let errors = applications.filter(application => application.status === 0);
        let running = applications.filter(application => application.status === 1);
        let ordered = sortApplicationsByName(errors)
                .concat(sortApplicationsByName(running));
        return ordered;
    }

    const sortApplicationsByName = (applications) => {
        return applications.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            if (nameA === nameB) {
                return sortApplicationsByEnvironment(a, b);
            }
        });
    };

    const sortApplicationsByEnvironment = (a, b) => {
        const environmentA = a.environment;
        const environmentB = b.environment;
        if (environmentA < environmentB) {
            return -1;
        }
        if (environmentA > environmentB) {
            return 1;
        }
        return 0;
    }
    
    return (
        <div className='app-grid'>
            {sortApplications(applications).map((application, i) => (
                <Application application={application} key={i} />
            ))}
        </div>
    );
}