import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Header.css';
import { faCheck, faPlug, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Header({ name, status, environment }) {

    return (
        <div className='application-header' app-status-data={status}>
            <section className='app-status'>
                <FontAwesomeIcon icon={faTimes} size='lg' className='error' />
                <FontAwesomeIcon icon={faCheck} size='lg' className='running' />
                <FontAwesomeIcon icon={faQuestion} size='lg' className='stale' />
                <FontAwesomeIcon icon={faPlug} size='lg' className='api' />
            </section>
            <div className="title-container">
                <div className='title'>
                    {name}
                </div>
                <div className='environment'>
                    {environment}
                </div>
            </div>
        </div>
    );
}