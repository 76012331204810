// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    padding: 1rem .75rem;
    background-color: #090a11;
    color: #dee2e6;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".header {\n    padding: 1rem .75rem;\n    background-color: #090a11;\n    color: #dee2e6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
