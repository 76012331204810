import './Drawer.css';
import Module from './Module';
import Tag from './Tag';

export default function Drawer({ modules, tags }) {

    return (
        <div className='drawer'>
            {modules.map((module, i) => (
                <Module key={i}
                    name={module.name}
                    status={module.status}
                    note={module.note}
                    additionalInfo={module.additionalInfo}
                />
            ))}
            {/* {tags.map((tag, i) => (
                <Tag key={i} tag={tag}/>
            ))} */}
        </div>
    );
}