import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Module.css';
import { faCheck, faExclamationTriangle, faGlobe, faInfoCircle, faLink, faServer, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Module({ name, status, note, additionalInfo }) {

    const openLink = url => {
        window.open(url, '_blank');
    }

    return (
        <div className='module' module-status-data={status}>
            <FontAwesomeIcon icon={faGlobe} className='home' />
            <FontAwesomeIcon icon={faLink} className='link' />
            <FontAwesomeIcon icon={faTimes} className='error' />
            <FontAwesomeIcon icon={faCheck} className='running' />
            <FontAwesomeIcon icon={faExclamationTriangle} className='warning' />
            <FontAwesomeIcon icon={faInfoCircle} className='info' />
            <FontAwesomeIcon icon={faServer} className='server' />

            <span className="module-title">
                {status === 'HOME' || status === 'LINK' ? (
                    <span className='module-link' title={note} onClick={() => openLink(note)}>
                        {name}
                    </span>
                ) : (name)}
            </span>
            <hr />
            <span className="module-description">
                {status === 'HOME' || status === 'LINK' ? ('') : (
                    <span title={additionalInfo}>{note}</span>
                )}
            </span>
        </div>
    );
}