// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-grid {
    flex: 1 0 80%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    padding-top: 3.5%;
    padding-left: 2.5%;
    padding-right: 0.5%;
    max-height: calc(100% + 20px);
    overflow: auto;
    position: relative;
    top: -20px;
}
  `, "",{"version":3,"sources":["webpack://./src/components/app-grid/AppGrid.css"],"names":[],"mappings":"AAAA;IAGI,aAAa;IAGb,aAAa;IAEb,eAAe;IAEf,yBAAyB;IAGzB,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;IACd,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".app-grid {\n    -webkit-box-flex: 1;\n    -ms-flex: 1 0 80%;\n    flex: 1 0 80%;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: wrap;\n    flex-wrap: wrap;\n    -ms-flex-line-pack: start;\n    align-content: flex-start;\n    -webkit-box-align: start;\n    -ms-flex-align: start;\n    align-items: flex-start;\n    padding-top: 3.5%;\n    padding-left: 2.5%;\n    padding-right: 0.5%;\n    max-height: calc(100% + 20px);\n    overflow: auto;\n    position: relative;\n    top: -20px;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
